import React, { useState } from 'react'
import Header from '../features/header';
import { getToken, FetchToken } from '../store/getToken';
import "../App.css";
import { useForm, SubmitHandler } from 'react-hook-form';

// Define the form data structure
interface IFormInput {
    name: string;
    email: string;
    message: string;
}


export default function Contact() {

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        reset
    } = useForm<IFormInput>({
        mode: 'onChange' // Enable validation on change
    });

    const onSubmit: SubmitHandler<IFormInput> = (data) => {
        console.log('Form Submitted:', data);
        // Here you can handle sending the form data to a backend server
        reset(); // Reset form after submission
    };

    return (
        <>
            <Header />

            <div className="pageBackground">
                <div className="container text-white">
                <h3>Contact:</h3>
                <br/>
                <p>Let us know what you think, or what you want to know.</p>
                <p>If your question requires a response, please allow up to 48 hours.</p>
                    <div className="row">
                        <div className="col-3"></div>
                        <div className="col-6">
                            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                                {/* Name Input */}
                                <div className="row">
                                    <div className="col-3">
                                        <label htmlFor="name">Name</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="contactText text-dark text-start"
                                            id="name"
                                            type="text"
                                            {...register('name', {
                                                required: 'Name is required',
                                                minLength: {
                                                    value: 3,
                                                    message: 'Name must be at least 3 characters long',
                                                },
                                            })}
                                        />
                                        {errors.name && <span>{errors.name.message}</span>}
                                    </div>
                                </div>

                                {/* Email Input */}
                                <div className="row">
                                    <div className="col-3">
                                        <label htmlFor="email">Email</label>
                                    </div>
                                    <div className='col-9'>
                                    <input
                                        className="contactText text-dark text-start"
                                        id="email"
                                        type="email"
                                        {...register('email', {
                                            required: 'Email is required',
                                            pattern: {
                                                value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                                message: 'Enter a valid email address',
                                            },
                                        })}
                                    />
                                    {errors.email && <span>{errors.email.message}</span>}
                                    </div>
                                </div>

                                {/* Message Input */}
                                <div className="row">
                                    <div className="col-3">
                                        <label htmlFor="message">Message</label>
                                    </div>
                                    <div className="col-9">
                                    <textarea
                                        className="contactText text-dark text-start"
                                        id="message"
                                        {...register('message', {
                                            required: 'Message is required',
                                            minLength: {
                                                value: 10,
                                                message: 'Message must be at least 10 characters long',
                                            },
                                        })}
                                    ></textarea>
                                    {errors.message && <span>{errors.message.message}</span>}
                                    </div>
                                </div>

                                {/* Submit Button */}
                                <div>
                                    <button type="submit" disabled={!isValid} className="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-3"></div>
                    </div>
                </div>
            </div>
        </>
    );
};