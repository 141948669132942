import Header from '../features/header';
import "../App.css";


export default function About() {
    return (
        <>
            <Header />
            <div className="pageBackground">
                <div className="container text-white">
                    <h1 className="text-white">About Page</h1>
                    <p>Welcome to the about page of our application!</p>

                </div>
            </div>
        </>
    );
}