import Header from '../features/header';
import "../App.css";


export default function RecordStore() {
    return (
        <>
            <Header />
            <div className="pageBackground">
                <div className="container text-white">
                    <h1 className="text-white">Record store...</h1>
                    <p>Welcome to the Record Store page of our application!</p>

                </div>
            </div>
        </>
    );
}