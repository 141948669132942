import { create } from 'zustand'

interface useState {
    page: string
    setPage: (message: string) => void
}

export const useStore = create<useState>()((set) => ({
    page: "",
    setPage: (page) => {
        set({ page: page })
    }
}))