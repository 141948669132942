import Home from './pages/home';
import Contact from './pages/contact';
import About from './pages/about';
import RecordStore from './pages/recordstore';
import { useStore } from './store/store';
import './App.css';

function App() {

  const page = useStore((state) => state.page); 

  switch (page) {
    case 'home':
      return <Home />;
    case 'about':
      return <About />;
    case 'contact':
      return <Contact />;
    case 'recordstore':
      return <RecordStore />;
    default:
      return <Home />;
  }
  
}

export default App;
